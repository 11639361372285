import { useRouter } from 'next/router'
import React from 'react'

import { Locale, makeLocale } from '../components/helpers'
import { InfoCard } from '../components/info-card'
import { SessionlessPage } from '../components/page-wrappers'

const translations: {
  [key in Locale]: {
    heading: string
    body: string
    button: string
  }
} = {
  sv: {
    heading: 'Sidan hittades inte',
    body: 'Sidan finns tyvärr inte längre.',
    button: 'Gå tillbaka',
  },
  en: {
    heading: 'Page not found',
    body: 'The page you where looking for could not be found.',
    button: 'Go back',
  },
  no: {
    heading: 'Siden ble ikke funnet',
    body: 'Siden eksisterer dessverre ikke lenger.',
    button: 'Gå tilbake',
  },
}

export default function Custom404() {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]

  const buttonConfig = {
    text: translation.button,
    onClick: () => router.push('/'),
  }

  return (
    <SessionlessPage>
      <InfoCard heading={translation.heading} body={translation.body} buttonConfig={buttonConfig} />
    </SessionlessPage>
  )
}
